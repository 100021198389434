import Application from '@shared/application'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Variant } from '@ts/shared/interfaces'

@Component
class HomeView extends Vue {
  private variants: Variant[] = _state.variants.data
  private selectedMaterials: (string | null)[] = new Array(1).fill(null)

  private get hasVariants() {
    return this.variants.length > 0
  }
}

export default new Application(HomeView)
